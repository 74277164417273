<template>
  <div class="brand-page__header">
    <div>
      <div class="c-page-header-container tw-flex tw-items-center tw-mx-4 tw-justify-between tw-justify-end">
        <div class="logo tw-relative">
          <div><a href="/" class="nuxt-link-active">
              <div class="logo tw-relative">
                <figure class="logo__container"><img src="@/assets/logo.png" alt="My BrandSite's logo"
                    loading="lazy" class="logo__image tw-object-left" style="height: 2.5rem;"></figure>
              </div>
            </a></div>
        </div>
        <div
          slug="victoryimgroup" class="tw-mt-6">
          <div class="tw-w-full tw-flex tw-justify-end tw-mb-6"><button @click="changeNav" id="buttonId" class="tw-p-2 tw-cursor-pointer"
              style="border-width: 0px; border-radius: 8px; background-color: rgb(14, 37, 140); color: rgb(255, 255, 255);">
              <div class="tw-h-4"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="graphics-symbol"
                  aria-labelledby="title" aria-describedby="desc" class="tw-fill-current tw-w-4 tw-h-4">
                  <title id="hamburger-1928447-title" lang="en">Hamburger </title>
                  <desc id="hamburger-1928447-desc" lang="en">Hamburger Icon </desc>
                  <g transform="translate(1 2)" fill-rule="nonzero">
                    <rect width="14" height="2.4" rx="1.2"></rect>
                    <rect y="4.8" width="14" height="2.4" rx="1.2"></rect>
                    <rect y="9.6" width="14" height="2.4" rx="1.2"></rect>
                  </g>
                </svg></div>
            </button></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 导航 -->
  <div>
    <div id="popOverlay" class="c-overlay" :class="[showNav?'c-overlay--open':'c-overlay--closed']" @click="changeNav"></div>
    <div id="popSidebar" class="c-sidebar" :class="[showNav?'c-sidebar--open':'c-sidebar--closed']"
        style="border-style:solid;border-radius:0px;border-bottom-width:0px;border-left-width:0px;border-right-width:0px;border-top-width:0px;background-color:#ffffff;color:#0e258c;">
        <div class="tw-w-full tw-flex tw-justify-end"><button id="close" @click="changeNav"
                class="tw-mt-4 tw-mr-4 tw-w-8 tw-h-8 tw-bg-transparent tw-border-none tw-cursor-pointer"
                style="border-style:solid;border-radius:0px;border-bottom-width:0px;border-left-width:0px;border-right-width:0px;border-top-width:0px;background-color:#ffffff;color:#0e258c;"><span
                    class="tw-sr-only">Close modal</span>
                <div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="graphics-symbol"
                        aria-labelledby="title" aria-describedby="desc" class="tw-fill-current tw-w-4 tw-h-4">
                        <title id="close-1928469-title" lang="en">Close</title>
                        <desc id="close-1928469-desc" lang="en">Close Icon</desc>
                        <path
                            d="M12.7857 3.2143c-.2857-.2857-.7143-.2857-1 0L8 7 4.2143 3.2143c-.2857-.2857-.7143-.2857-1 0-.2857.2857-.2857.7143 0 1L7 8l-3.7857 3.7857c-.2857.2857-.2857.7143 0 1 .1428.1429.2857.2143.5.2143s.3571-.0714.5-.2143L8 9l3.7857 3.7857c.1429.1429.3572.2143.5.2143.1429 0 .3572-.0714.5-.2143.2857-.2857.2857-.7143 0-1L9 8l3.7857-3.7857c.2857-.2857.2857-.7143 0-1z"
                            fill-rule="nonzero"></path>
                    </svg></div>
            </button></div>
        <div class="tw-flex tw-flex-col nav-list">
            <a href="/"
                class="tw-no-underline tw-p-4 tw-basis-0" :class="[hasNav('/')?'nuxt-link-active':'']">
                Home
            </a><a href="/services" class="tw-no-underline tw-p-4 tw-basis-0" :class="[hasNav('/services')?'nuxt-link-active':'']">
                Our Services
            </a><a href="/get-in-touch-with-a-partner" :class="[hasNav('/get-in-touch-with-a-partner')?'nuxt-link-active':'']"
                class="tw-no-underline tw-p-4 tw-basis-0 nuxt-link-exact-active">
                Get in touch with a Partner
            </a><a href="/about" class="tw-no-underline tw-p-4 tw-basis-0" :class="[hasNav('/about')?'nuxt-link-active':'']">
                About
            </a><a href="/advisors" class="tw-no-underline tw-p-4 tw-basis-0" :class="[hasNav('/advisors')?'nuxt-link-active':'']">
                Our Advisors
            </a><a href="/achievement" class="tw-no-underline tw-p-4 tw-basis-0" :class="[hasNav('/achievement')?'nuxt-link-active':'']">
                Achievement
            </a></div>
    </div>
</div>
</template>

<script setup>
  import { useI18n } from "vue-i18n";
  import { ref,onMounted, onUnmounted } from "vue";
  const showNav = ref(false)

  const changeNav = ()=>{
    showNav.value = !showNav.value
  }

  const hasNav = (name) =>{
    return document.location.pathname === name
  }
  onMounted(() => {
    
  });

</script>

<style lang="scss" scoped>
  .nav-list{
    a{
      border-style:solid;
      border-radius:0px;
      border-bottom-width:0px;
      border-left-width:0px;
      border-right-width:0px;
      border-top-width:0px;
      background-color:#ffffff;
      color:#0e258c;
      &.nuxt-link-active{
        border-style:solid;
        border-radius:0px;
        border-bottom-width:0px;
        border-left-width:0px;
        border-right-width:0px;
        border-top-width:0px;
        background-color:#0e258c;
        color:#ffffff;
      }
    }
}
</style>