<template>
    <div style="container-type:inline-size;">
      <div
        class="tw-flex tw-items-center c-page-footer__container-justified c-page-footer__container-justified c_footer-container">
        <div class="logo tw-relative">
          <div>
            <a href="/" aria-current="page" class="nuxt-link-exact-active nuxt-link-active">
              <div class="logo tw-relative">
                <figure class="logo__container"><img src="@/assets/logo.png" alt="My BrandSite's logo"
                    loading="lazy" class="logo__image" style="height:2.5rem;">
                </figure>
              </div>
            </a>
          </div>
  
        </div>
        <div class="tw-break-all" style="text-align:center;">© 2023 Manulife Financial Corporation
          All Rights Reserved.
        </div>
        <!-- <ul class="c-social-links__links-container">
          <li>
            <a href="mailto:support@unionmaga.com"
              target="_blank" class="tw-leading-none c-social-links__icon-container">
              <div is-fill-current="true" alt="icon" style="fill:#0e258c;"><svg
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="presentation"
                  class="c-icon c-icon--md">
  
                  <path
                    d="M14.125 3H1.875C1.392 3 1 3.3193 1 3.7143v8.5714c0 .395.392.7143.875.7143h12.25c.483 0 .875-.3193.875-.7143V3.7143C15 3.3193 14.608 3 14.125 3zm-.875 8.5714H2.75V7.088l4.816 2.2464c.2695.1257.5994.1257.868 0l4.816-2.2464v4.4835zm0-6.1285L8 7.892 2.75 5.443V4.4286h10.5v1.0143z"
                    fill-rule="nonzero"></path>
                </svg></div>
            </a>
          </li>
        </ul> -->
      </div>
  
    </div>
    <div class="email-box">
      <img src="@/assets/images/email.png">
      <div class="email-list">
        <div>
          Unionmaga: <a href="mailto:support@unionmaga.com">unionmaga@outlook.com</a>
        </div>
        <div>
          ManulifeGroup: <a href="mailto:support@unionmaga.com">manulifegroup@outlook.com</a>
        </div>
      </div>
    </div>
    <div class="email-box email-box-mobile" @click="show=true">
      <img src="@/assets/images/email.png">
    </div>
    <div class="popup-mobile" v-if="show">
      <div class="popup-mobile-bg" @click="show=!show"></div>
      <div class="email-list-mobile">
        <div>
          Unionmaga: <a href="mailto:support@unionmaga.com">unionmaga@outlook.com</a>
        </div>
        <div>
          ManulifeGroup: <a href="mailto:support@unionmaga.com">manulifegroup@outlook.com</a>
        </div>
      </div>
    </div>
</template>

<script setup>
import { onMounted,ref } from 'vue';
const show = ref(false)
onMounted(()=>{
})
</script>

<style lang="scss" scoped>
  .email-box{
    position: fixed;
    right: 0;
    bottom: 20px;
    img{
      width: 6rem;
      cursor: pointer;
    }
    @media (min-width:640px) {
      &.email-box-mobile{
        display: none;
      }
      &{
        display: block;
      }
      &:hover{
        .email-list{
          display: block;
        }
      }
    }
    @media (max-width:640px) {
      &.email-box-mobile{
        display: block;
      }
      &{
        display: none;
      }
    }
    .email-list{
      display: none;
      position: absolute;
      right: 6rem;
      top: 0;
      background: #fff;
      border-radius: 1rem;
      padding: 1rem 2rem;
      box-shadow: 0px 4px 10px 0px #00000040;
      div{
        text-wrap: nowrap;
        text-align: right;
        a{
          color: #000000;
        }
      }
    }
  }
  
  .popup-mobile{
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      .popup-mobile-bg{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
      .email-list-mobile{
        position: relative;
        z-index: 10;
        background: #fff;
        border-radius: 1rem;
        padding: 1rem 2rem;
        div{
          text-wrap: nowrap;
          &:first-of-type{
          }
          margin-bottom: 1rem;
          a{
            display: block;
            color: #000000;
          }
        }
      }
    }
</style>