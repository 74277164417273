import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/support",
    name: "support",
    component: () => import(/* webpackChunkName: "support" */ "../views/Support.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(
        /* webpackChunkName: "services" */ '@/views/services.vue'
      )
  },
  {
    path: "/achievement",
    name: "achievement",
    component: () =>
      import(
        /* webpackChunkName: "achievement" */ '@/views/achievement.vue'
      )
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutus.vue"),
  },
  {
    path: "/advisors",
    name: "advisors",
    component: () =>
      import(
        /* webpackChunkName: "advisors" */ '@/views/advisors.vue'
      )
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/aboutus.vue"),
  },
  {
    path: "/get-in-touch-with-a-partner",
    name: "get-in-touch-with-a-partner",
    component: () =>
      import(/* webpackChunkName: "contactus" */ "@/views/contactus.vue"),
  },
];
const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top:0 };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
